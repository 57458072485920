/* You can add global styles to this file, and also import other style files */

:root,
html,
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: url("/assets/background.png") no-repeat fixed right bottom /
    contain;
}

pre,
.card {
  background-color: inherit;
  color: inherit;
  border-color: white;
}

.container {
  max-width: 3840px;
}

.report-main-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
}
